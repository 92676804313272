<template>
  <div class="maintenance-details py-8">
    <RequestAppbar />

    <v-row class="mx-4">
      <v-col cols="12" class="ma-0 pa-0">
        <ViewingTitle :title="displayName" class="maintenance-details__title" />
        <DeleteOrRestore
          v-if="hasDeletePermissions"
          :model="maintenance"
          :max-width="500"
          name="maintenance request"
          redirect="/maintenance/dashboard"
        />

        <div class="d-flex mb-6">
          <v-tabs hide-slider class="maintenance-info-tabs">
            <v-tab class="pa-0" v-for="(item, key) in tabItems" :key="key">
              {{ item.tab }}
            </v-tab>

            <v-tab-item class="col-sm-12 col-xs-12 mt-6 pa-0">
              <v-divider class="maintenance-details__divider"></v-divider>

              <RequestForm
                class="maintenance-details__form"
                :maintenance="maintenance"
              />
            </v-tab-item>
          </v-tabs>
        </div>
      </v-col>
    </v-row>

    <Loading :value="loading" />
  </div>
</template>
<script>
/**
 * ==================================================================================
 * View Maintenance Request
 * ==================================================================================
 **/

import { mapState, mapActions, mapMutations } from 'vuex'
import ViewingTitle from '@/components/common/ViewingTitle'
import RequestForm from '@/views/Home/Maintenance/Requests/components/RequestForm'
import RequestAppbar from '@/views/Home/Maintenance/Requests/components/RequestAppbar'
import SnackbarMixin from '@/utils/mixins/Snackbar'
import ErrorHandlerMixin from '@/utils/mixins/ErrorHandler'
import Loading from '@/components/common/Loading'
import DeleteOrRestore from '@/components/fields/DeleteOrRestore'
import { validatePermissions } from '@/utils/auth'
import PERMISSION from '@/utils/enums/Permission'

export default {
  name: 'MaintenanceDetails',

  components: {
    ViewingTitle,
    RequestAppbar,
    RequestForm,
    Loading,
    DeleteOrRestore,
  },

  mixins: [SnackbarMixin, ErrorHandlerMixin],

  data() {
    return {
      loading: false,
      tabItems: [{ tab: 'Info' }],
    }
  },

  computed: {
    ...mapState({
      maintenance: (state) => state.maintenance.maintenanceDetails,
      permissions: (state) => state.auth.permissions,
    }),

    displayName() {
      return this.maintenance ? this.maintenance.title : null
    },

    hasDeletePermissions() {
      return validatePermissions(
        [PERMISSION.MAINTENANCES_DELETE],
        this.permissions
      )
    },
  },

  methods: {
    ...mapActions({
      getMaintenanceDetails: 'maintenance/getMaintenanceDetails',
    }),

    ...mapMutations({
      clearMaintenanceDetails: 'maintenance/clearMaintenanceDetails',
    }),

    async getMaintenance() {
      this.loading = true
      await this.getMaintenanceDetails(this.$route.params.id)
        .catch((err) => {
          this.showSnackbar(this.getErrorMessage(err), false)
        })
        .finally(() => {
          this.loading = false
        })
    },
  },

  created() {
    this.getMaintenance()
  },

  destroyed() {
    this.clearMaintenanceDetails()
  },

  watch: {
    $route() {
      this.getMaintenance()
    },
  },
}
</script>

<style lang="scss" scoped>
.maintenance-details {
  &__title {
    padding-right: 10px;
  }

  &__divider {
    max-width: 500px;
  }

  @media (max-width: 768px) {
    &__divider {
      max-width: 100%;
    }
  }
}
</style>
