<template>
  <div class="maintenance-file-upload">
    <FileMultiUpload
      :images="form.images"
      :loading="form.$busy"
      :disabled="isDisabled"
      :max-image="4"
      :can-upload="false"
      class="maintenance-details__uploader"
    />
  </div>
</template>
<script>
/**
 * ==================================================================================
 * Bookable: File Upload
 * ==================================================================================
 **/

import { mapState } from 'vuex'
import FileMultiUpload from '@/components/fields/FileMultiUpload'
import Form from '@/utils/form'

export default {
  components: {
    FileMultiUpload,
  },

  data() {
    return {
      form: new Form({
        images: [],
      }),
    }
  },

  computed: {
    ...mapState({
      maintenance: (state) => state.maintenance.maintenanceDetails,
    }),

    isDisabled() {
      return this.maintenance ? !!this.maintenance.deleted_at : false
    },
  },

  watch: {
    maintenance(newValue, oldValue) {
      this.initForm()
    },
  },

  mounted() {
    this.initForm()
  },

  methods: {
    initForm() {
      if (this.maintenance) {
        this.form.images = this.maintenance.media || []
      }
    },
  },
}
</script>
